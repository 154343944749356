<template>
  <div>
    <v-layout>
      <v-btn icon class="primary--text mt-1 mr-2" :to="`/`" router exact>
        <v-icon>keyboard_backspace</v-icon>
      </v-btn>

      <page-title>Registered Users</page-title>

      <div class="ml-4 mt-1 mb-2">
        <v-btn class="primary--text mr-3" text @click="refresh()">
          <v-icon class="mr-1">refresh</v-icon>
          Refresh
        </v-btn>
      </div>
    </v-layout>

    <v-layout>
      <page-subtitle>Create, view and manage system users.</page-subtitle>
    </v-layout>

    <!-- Error message -->
    <v-layout>
      <div v-if="error">
        <v-alert type="error" dense outlined>
          {{ error }}
        </v-alert>
      </div>
    </v-layout>

    <!-- delete confirmation modal -->
    <v-dialog v-model="removeProjectModal" max-width="500px">
      <v-card>
        <v-card-title>Confirmation Required</v-card-title>
        <v-card-text>
          Are you sure want to delete project '{{ removingProject.id }}'? This
          will remove all devices, namespaces. applications, secrets,
          provisioning tokens, etc.
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            v-on:click.native="removeProjectModal = false"
            class="primary--text"
            >Cancel</v-btn
          >
          <v-spacer> </v-spacer>
          <v-btn
            v-on:click.native="deleteProjectConfirmed()"
            class="error white--text"
          >
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

     <v-dialog v-model="removeUserModal" max-width="500px">
      <v-card>
        <v-card-title>Confirmation Required</v-card-title>
        <v-card-text>
          Are you sure want to delete user '{{ removingUser.id }}'? User can't
          be deleted if it owns any projects. Delete those first.
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            v-on:click.native="removeUserModal = false"
            class="primary--text"
            >Cancel</v-btn
          >
          <v-spacer> </v-spacer>
          <v-btn
            v-on:click.native="deleteUserConfirmed()"
            class="error white--text"
          >
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit project quota -->
     <!-- edit labels -->
    <v-dialog v-model="editProjectQuotaModal" max-width="400px">
      <v-card>
        <v-card-title>Edit Quota ({{editProjectQuota.projectName}})</v-card-title>        
        <v-card-text>
          <form v-on:submit.prevent="updateProjectQuotaEditConfirmed()">
            <v-text-field required label="Devices" v-model="editProjectQuota.devices" />
            <v-text-field required label="Namespaces" v-model="editProjectQuota.namespaces" />
            <v-text-field required label="Applications" v-model="editProjectQuota.applications" />
            <v-text-field required label="Secrets" v-model="editProjectQuota.secrets" />
            
            <v-btn v-show="false" hidden type="submit"></v-btn>
          </form>
          <div v-if="error">
            <v-alert error close v-model="error">
              {{ error }}
            </v-alert>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            v-on:click.native="closeProjectQuotaEditEditModal()"
            class="primary--text"
            >Close</v-btn
          >
          <v-spacer> </v-spacer>
          <v-btn
            v-on:click.native="updateProjectQuotaEditConfirmed()"
            class="primary white--text"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout class="mt-2">
      <div class="tbcard">
        <div v-if="!loading && registeredUsers.length === 0">
          <v-container fill-height align-content-space-around>
            <v-layout row justify-center>
              <v-flex xs4 class="text-md-center mt-5">
                <span class="headline">No Registered Users</span>
                <span class="subheading">
                  <page-subtitle class="text-md-center">
                    If there are no users.. then... who are you?
                  </page-subtitle>
                </span>
              </v-flex>
            </v-layout>
          </v-container>
        </div>

        <v-card v-else-if="registeredUsers.length > 0" outlined>
          <v-data-table
            :key="tableKey"
            :headers="headers"
            :items="registeredUsers"
            :search="e3"
          >
            <template v-slot:[`item.id`]="{ item }">
               <span>
                  {{ item.id }}
                  <v-btn
                    class="primary--text mr-3"
                    text
                    x-small
                    @click="removeUserIntent(item)"
                  >
                    <v-icon class="mr-1">delete</v-icon>
                  </v-btn>
                  <br />
                </span>
            </template>

            <template v-slot:[`item.projects`]="{ item }">

               <v-list dense >               
                <v-list-item-group
                 
                  color="primary"
                >
                  <v-list-item
                    v-for="(membership, i) in item.memberships"
                    :key="`${item.id}-${membership.projectId}-${i}`"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="membership.projectId"></v-list-item-title>
                    </v-list-item-content>
                    
                    <v-list-item-icon class="ml-2">
                      <v-icon @click="editProjectQuotaIntent(membership)">edit</v-icon>
                    </v-list-item-icon>
                    
                    <v-list-item-icon class="ml-3">
                      <v-icon @click="selectProject(membership)">touch_app</v-icon>
                    </v-list-item-icon>

                    <v-list-item-icon>
                      <v-icon @click="removeProjectIntent(membership)">delete</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </template>

            <template v-slot:[`item.createdAt`]="{ item }">
              <v-tooltip top :key="item.id">
                <template v-slot:activator="{ on: tooltip }">
                  <span v-on="{ ...tooltip }">
                    {{ item.createdAt | ago }}
                  </span>
                </template>
                <span>{{ item.createdAt | date }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-layout>
  </div>
</template>

<style lang="stylus">
.tbcard {
  width: 100%;
  display: block;
}
</style>

<script>
import PageSubtitle from '../PageSubtitle';
import PageTitle from '../PageTitle';

export default {
  components: {
    PageSubtitle,
    PageTitle
  },
  data() {
    return {
      tableKey: '',
      e3: null,
      headers: [
        { text: 'ID', align: 'left', value: 'id', sortable: true },
        { text: 'Name', value: 'name', align: 'left' },
        { text: 'Email', value: 'email', align: 'left' },
        { text: 'User Type', value: 'userType', align: 'left' },
        { text: 'Provider', value: 'providerName', align: 'left' },
        { text: 'Projects', value: 'projects', align: 'right' },
        { text: 'Admin', value: 'superAdmin', align: 'left' },
        { text: 'Devices', value: 'deviceCount', align: 'left' },
        { text: 'Created', value: 'createdAt', align: 'left' },
        { text: '', value: 'actions', sortable: false, align: 'right' }
      ],
      removeProjectModal: false,
      removeUserModal: false,
      removingProject: {
        id: ''
      },
      removingUser: {
        id: ''
      },
      editProjectQuotaModal: false,
      editProjectQuota: {
        id: '',
        devices: 10,
        namespaces: 10,
        applications: 10,
        secrets: 100
      }
    }
  },

  watch: {
    removeProjectModal(val) {
      val || this.cancelDeletion()
    },
    removeUserModal(val) {
      val || this.cancelDeletion()
    },
  },

  computed: {
    error() {
      return this.$store.state.admin.error
    },
    loading() {
      return this.$store.state.admin.loading
    },
    registeredUsers() {
      return this.$store.state.admin.registeredUsers
    },
  },

  mounted() {
    this.refresh()
  },

  methods: {
    refresh() {
      this.$store.dispatch('ListRegisteredUsers')
    },
    selectProject(selectedProject) {
      this.$store.dispatch('SelectProject', selectedProject.projectId)
      this.$store.dispatch('Notify', `Selected project ${selectedProject.projectId}`)
    },
    removeProjectIntent(removingProject) {
      this.removingProject = Object.assign({}, removingProject)
      this.removeProjectModal = true
    },
    removeUserIntent(removingUser) {
      this.removingUser = Object.assign({}, removingUser)
      this.removeUserModal = true
    },
    deleteProjectConfirmed() {
      let payload = {
        id: this.removingProject.projectId
      }
      this.$store.dispatch('DeleteProject', payload).then(() => {
        this.refresh()
        // closing confirmation modal
        this.removeProjectModal = false
      })
    },
     deleteUserConfirmed() {
      let payload = {
        id: this.removingUser.id
      }
      this.$store.dispatch('DeleteUser', payload).then(() => {
        this.refresh()
        // closing confirmation modal
        this.removeUserModal = false
      })
    },
    cancelDeletion() {
      this.removeProjectModal = false
      this.$nextTick(() => {
        this.removingProject = {
          id: ''
        }
      })
    },
    async editProjectQuotaIntent(selectedProject) {
      let q = {
        projectId: selectedProject.projectId
      }
      await this.$store.dispatch('GetProject', q)

      this.editProjectQuota = Object.assign({}, this.$store.state.project.project.quota)
      this.editProjectQuota.projectId = selectedProject.projectId
      this.editProjectQuota.projectName = this.$store.state.project.project.name
      this.editProjectQuotaModal = true
    },
    updateProjectQuotaEditConfirmed() {
      // Converting to int
      this.editProjectQuota.devices = parseInt(this.editProjectQuota.devices, 10)
      this.editProjectQuota.namespaces = parseInt(this.editProjectQuota.namespaces, 10)
      this.editProjectQuota.applications = parseInt(this.editProjectQuota.applications, 10)
      this.editProjectQuota.secrets = parseInt(this.editProjectQuota.secrets, 10)
      
      this.$store.dispatch('UpdateProjectQuota', this.editProjectQuota).then(() => {
        this.refresh()
        // closing confirmation modal
        this.editProjectQuotaModal = false
      })
    },
    closeProjectQuotaEditEditModal () {
      this.editProjectQuotaModal = false
      // default
      this.editProjectQuota = {
        id: '',
        devices: 10,
        namespaces: 10,
        applications: 10,
        secrets: 100
      }
    }
  }
}
</script>
